import React from 'react'
import {StartReadingButton} from './WelcomeOverlayComponents';
import {PromptMessage} from '../ReadingInterfaceComponents';
import { useSearchParams } from "react-router-dom";

const WelcomeOverlay = (props) => {
  let [searchParams] = useSearchParams();
  let jadisVisible = false;
  const jadis = searchParams.get("jadis");
  if (jadis === 'true') {
    jadisVisible = true;
  }

  return (
    <div>
      {jadisVisible && <PromptMessage>Hello. My name is Jadis. I can predict your future...</PromptMessage>}
      <StartReadingButton onClick={props.showNextMenu}>Start a Tarot Reading</StartReadingButton>
    </div>
  )
}

export {WelcomeOverlay};
