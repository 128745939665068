import React from 'react';
import styled from "styled-components";
import {JadisStill, SceneContainer,RadialGradient,LeftLinGrad,RightLinGrad,Fog} from "./SceneComponents";
import { useSearchParams } from "react-router-dom";

const Scene = () => {
  let [searchParams] = useSearchParams();
  let jadisVisible = false;
  const jadis = searchParams.get("jadis");
  if (jadis === 'true') {
    jadisVisible = true;
  }
  return (
    <SceneContainer>
        { jadisVisible && <JadisStill src='/assets/jadis.png' /> }
        <RadialGradient></RadialGradient>
        <LeftLinGrad></LeftLinGrad>
        <RightLinGrad></RightLinGrad>
        <Fog src='/assets/fog.png' />
    </SceneContainer>
  )
}

export default Scene;
