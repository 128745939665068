import {React, useState} from "react";
import {SignOut, NavbarWrapper, NavLink, StyledAnchor, Hamburger, NavMenu, SiteTitle}
    from "./NavbarComponents";
import {ReactSVG} from 'react-svg';
import {FaBars} from 'react-icons/fa';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

    const Navbar = () => {
        const [isNavExpanded, setIsNavExpanded] = useState(false)
        return (
            <>
                <NavbarWrapper $isNavExpanded={isNavExpanded}>
                    <SiteTitle>Halo Publishing</SiteTitle>
                    <Hamburger onClick={() => {
                                setIsNavExpanded(!isNavExpanded);
                               }}>
                        {isNavExpanded ? <ReactSVG src="/assets/icons/x.svg" /> : <FaBars />} 
                    </Hamburger>
                    <NavMenu class="hasTransition" $isNavExpanded={isNavExpanded}>
                        <NavLink to="/" $activeStyle>
                            Tarot Reading
                        </NavLink>
                        <StyledAnchor href="https://halopublishing.store"> 
                            Store
                        </StyledAnchor>
                        {/* <NavLink to="/forum" activeStyle>
                            Tarot Forum
                        </NavLink> */}
                        <SignOut onClick={() => firebase.auth().signOut()}>Sign out</SignOut>
                    </NavMenu>
                </NavbarWrapper>
            </>
        );
    };
    export default Navbar;
