// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin:0;
  background-color: rgba(8, 12, 20, 1);
  padding: 0;
}

.hasTransition {
  transition: all 0.5s ease-in-out;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,oCAAoC;EACpC,UAAU;AACZ;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":["body {\n  margin:0;\n  background-color: rgba(8, 12, 20, 1);\n  padding: 0;\n}\n\n.hasTransition {\n  transition: all 0.5s ease-in-out;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
