import styled from "styled-components";
import { PromptMessage } from "../ReadingInterfaceComponents";

export const ContinueButton = styled.button`
    position: absolute;
    z-index: 1;
    width: 200px;
    height: 60px;
    left: 50%;
    transform: translate(-50%, 0);
    top: 87.5%;
    max-width: 30%;
    color: #936250;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 72.727% */
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid #936250;
    background: radial-gradient(50.00% 50.75% at 50.00% 49.25%, rgba(147, 98, 80, 0.00) 35.42%, rgba(147, 98, 80, 0.08) 100%);
    &:hover {
        background-color: #b88b7a30;
    }
    @media (orientation: portrait) {
        top: 87.5%;
    }
`;

export const CustomizeReadingButton = styled.button`
    position: absolute;
    z-index: 1;
    width: 253px;
    height: 60px;
    left: 50%;
    transform: translate(-126%, 0);
    top: 87.5%;
    max-width: 30%;
    color: #936250;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 72.727% */
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid #936250;
    background: radial-gradient(50.00% 50.75% at 50.00% 49.25%, rgba(147, 98, 80, 0.00) 35.42%, rgba(147, 98, 80, 0.08) 100%);
    &:hover {
        background-color: #b88b7a30;
    }
    @media (orientation: portrait) {
        margin-top: 5px;
    }
`;

export const QuestionTextBox = styled.textarea`
    position: absolute;
    z-index: 1;
    width: 610px;
    resize: none;
    max-width: 80vw;
    height: 80px;
    top: 68%;
    left: 50%;
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    padding: 10px;
    font-weight: 400;
    line-height: 26px;
    transform: translate(-50%, 0);
    border-radius: 8px;
    border: 3px solid #936250;
    background: #070B13;
    @media (max-width: 768px) {
        top: 72%;
    }
    &::-webkit-scrollbar {
        width: 10px;
        
    }
    &::-webkit-scrollbar-thumb {
        border: 3px solid rgba(8, 12, 20, 1);
        background: #93625050;
    }

`;

// top should be 55% with Jadis visible
export const QuestionPromptMessage = styled(PromptMessage)`
    top: 35%;
    max-width: 75vw;
    @media (max-width: 768px) {
       
        font-size: 28px;
`;
